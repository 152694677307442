import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '~components/Layout'
import S from '~components/seo'
import { Hero } from '~components/Hero'
import { HeroPic } from './components/HeroPic'
import { AboutSection } from '~components/AboutSection'
import { Features } from '~components/Features'
import { Scheme } from '~components/Scheme'
import { Cases } from '~components/Cases'
import { Reviews } from '~components/Reviews'
import { Cta } from '~components/Cta'

export const Development = () => {
  const data = useStaticQuery(graphql`
    {
      developmentJson {
        hero {
          title
          descr
        }
        about {
          descr
        }
      }
    }
  `)
  const { hero, about } = data.developmentJson
  return (
    <Layout>
      <S title="Development" />
      <Hero {...hero} buttons>
        <HeroPic />
      </Hero>
      <AboutSection {...about} />
      <Features />
      <Scheme />
      <Cases />
      <Reviews />
      <Cta />
    </Layout>
  )
}
